import { isFeatureOn } from 'featureToggles';
import { byCountry } from 'utils/byConfig';
import {
  STEP_CONFIRM_INCOME,
  STEP_BIOMETRICS_COMPLETE,
  STEP_BIOMETRICS_CANCELLED,
  STEP_BIOMETRICS_RETURN,
  STEP_BIOMETRICS_IDENTIFICATION,
  STEP_BIOMETRICS_ADDRESS_DETAILS,
  STEP_LATITUDE_ID_INTRODUCTION,
  STEP_LATITUDE_ID,
  STEP_EIDV,
  STEP_PROCESSING_IDV,
  STEP_DAVID_JONES_PRESTIGE,
  STEP_DAVID_JONES_PREMIERE,
  STEP_DVS_CONSENT,
  STEP_VERIFY_DOCUMENT_UPLOAD,
  STEP_LOAN_INFORMATION,
  STEP_PROCESSING_LOAN_INFORMATION,
  STEP_PRODUCT_COMPARISON,
  STEP_SAVED,
  STEP_GO,
  STEP_TWENTY_EIGHT_DEGREES,
  STEP_AUS_POST,
  STEP_PROCESSING_BIOMETRICS,
  STEP_ADDITIONAL_CARD_HOLDER,
  STEP_EMAIL_VERIFICATION,
  STEP_CREDIT_LINE,
  STEP_GEM_AU,
  STEP_VERIFY_INSTORE,
  STEP_LOW_RATE,
  STEP_VERIFY_APPLICATION_MOBILE,
} from '../index';

const overviewConfig = { header: '', progress: 'Overview', footer: 'OVERVIEW' };

export function configByRouteMap(pathname, storeState) {
  return {
    '/': overviewConfig,
    [STEP_PRODUCT_COMPARISON]: { header: '', progress: 'Comparison', footer: 'OVERVIEW' },
    [STEP_DAVID_JONES_PRESTIGE]: overviewConfig,
    [STEP_DAVID_JONES_PREMIERE]: overviewConfig,
    [STEP_TWENTY_EIGHT_DEGREES]: overviewConfig,
    [STEP_GO]: overviewConfig,
    [STEP_GEM_AU]: overviewConfig,
    [STEP_CREDIT_LINE]: overviewConfig,
    [STEP_LOW_RATE]: overviewConfig,
    '/contact-details': { header: 'Your contact details', progress: 'You' },
    '/resume-your-application': {
      header: byCountry({ NZ: 'Resume your application', AU: 'Resume application' }),
      progress: 'You',
    },
    '/resume-acceptance': { header: 'Resume your acceptance', progress: 'You' },
    '/verify': {
      header: storeState.oktaProfileHealthy ? 'Verify your mobile' : 'Verify your email',
      progress: 'You',
    },
    '/verify-to-resume': {
      header: storeState.oktaProfileHealthy ? 'Verify your mobile' : 'Verify your email',
      progress: 'You',
    },
    [STEP_VERIFY_APPLICATION_MOBILE]: { header: 'Verify your mobile', progress: 'You' },
    [STEP_LATITUDE_ID_INTRODUCTION]: { header: '', progress: 'Debts' },
    [STEP_LATITUDE_ID]: {
      header: byCountry({
        NZ: 'Create a Latitude ID',
        AU: isFeatureOn('emailVerification') ? 'Create your password' : 'Create a Latitude ID',
      }),
      progress: byCountry({
        NZ: 'You',
        AU: isFeatureOn('emailVerification') ? 'Debts' : 'You',
      }),
    },
    [STEP_EMAIL_VERIFICATION]: {
      header: 'Verify your email',
      progress: 'Debts',
    },
    '/password': { header: 'Create a password', progress: isFeatureOn('biometrics') ? 'Debts' : 'You' },
    '/verify-identity': { header: 'Verify your identity', progress: 'You' },
    [STEP_PROCESSING_BIOMETRICS]: byCountry({
      NZ: {
        header: "We've sent you an SMS to complete your identity verification",
        progress: 'You',
      },
      AU: {
        header: 'Biometric verification',
        progress: 'Debts',
      },
    }),
    [STEP_BIOMETRICS_COMPLETE]: { header: '', progress: 'You' },
    [STEP_BIOMETRICS_CANCELLED]: { header: '', progress: 'You' },
    [STEP_BIOMETRICS_RETURN]: { header: 'Welcome back!', progress: 'You' },
    '/biometrics-about-you': { header: 'About you', progress: 'You' },
    [STEP_BIOMETRICS_IDENTIFICATION]: { header: 'Identification', progress: 'Extra' },
    [STEP_BIOMETRICS_ADDRESS_DETAILS]: { header: 'Your address details', progress: 'Address' },
    [STEP_SAVED]: {
      header: byCountry({ NZ: 'Your application is saved', AU: 'Thank you for your application! ' }),
      progress: 'You',
    },
    '/about-you': { header: byCountry({ NZ: 'About you', AU: 'Tell us about yourself' }), progress: 'You' },
    '/address-details': {
      header: 'Your address details',
      progress: byCountry({
        AU: 'You',
        NZ: 'Address',
      }),
    },
    '/identification': { header: 'Identification', progress: 'Extra' },
    '/income': { header: byCountry({ NZ: 'Your income details', AU: 'Your employment details' }), progress: 'Work' },
    '/expenses': {
      header: byCountry({ NZ: 'Your expenses', AU: 'Your living expenses' }),
      progress: byCountry({
        AU: 'Work',
        NZ: 'Expenses',
      }),
    },
    '/commitments': {
      header: byCountry({ NZ: 'Your other commitments', AU: 'Other financial commitments ' }),
      progress: byCountry({
        AU: 'Work',
        NZ: 'Debts',
      }),
    },
    [STEP_ADDITIONAL_CARD_HOLDER]: {
      header: 'Add additional cardholder',
      progress: 'Work',
    },
    '/credit-limit': { header: 'Choose your credit limit', progress: 'Debts' },
    '/verify-income': {
      header: byCountry({ NZ: 'Verify Your Income', AU: 'Check your income' }),
      progress: 'Debts',
    },
    [STEP_LOAN_INFORMATION]: {
      header: 'Loan information',
      progress: 'Debts',
    },
    [STEP_PROCESSING_LOAN_INFORMATION]: { header: '', progress: 'Debts' },
    '/credit-limit-details': {
      header: byCountry({
        NZ: 'Tell us what credit limit meets your current circumstances',
        AU: null,
      }),
      progress: byCountry({
        AU: 'Work',
        NZ: 'Debts',
      }),
    },
    [STEP_DVS_CONSENT]: { header: 'Check your identity', progress: 'Debts' },
    [STEP_EIDV]: { header: 'Check your identity', progress: 'Debts' },
    [STEP_CONFIRM_INCOME]: { header: 'Confirm your income', progress: 'Debts' },
    [STEP_VERIFY_DOCUMENT_UPLOAD]: { header: 'Check your income', progress: 'Debts' },
    '/verify-ficonnect': { progress: 'Debts' },
    [STEP_PROCESSING_IDV]: { header: '', progress: 'Debts' },
    [STEP_AUS_POST]: { header: 'Verify your identity at Australia Post', progress: 'Debts' },
    [STEP_VERIFY_INSTORE]: { header: 'Verify your identity in-store', progress: 'Debts' },
    '/processing-poi': { header: '', progress: 'Debts' },
    '/processing-pending-poi': { header: '', progress: 'Debts' },
    '/processing': {
      header: '',
      progress: byCountry({
        AU: 'Work',
        NZ: 'Debts',
      }),
    },
    '/acceptance': {
      header: isFeatureOn('dynamicCreditLimit') ? 'Your preferred credit limit is' : 'Finalise your application',
      progress: 'Debts',
    },
    '/processing-acceptance': {
      header: '',
      progress: byCountry({
        NZ: 'Processing',
        AU: 'Debts',
      }),
    },
    '/outcome': { header: '', progress: 'Outcome', footer: 'OUTCOME' },
    '/outcome-timeout': { header: '', progress: 'Outcome', footer: 'OUTCOME' },
    '/outcome-fin-table': { header: '', progress: 'Outcome', footer: 'OUTCOME' },
    '/resume-application-expired': { header: '', progress: 'Outcome', footer: 'OUTCOME' },
  }[pathname];
}
