import React from 'react';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, Text, Link, List } from 'basis';
import { OverviewModalContentLowRate } from 'siteContent/overviewContent';
import { OverviewSources } from '_config';
import { getProductName } from 'utils';

const LowRateFeaturesAndBenefits = ({ isOpen, onClose, getLinkWithAnalytics, trackEventSender }) => {
  const OverviewModalContent = OverviewModalContentLowRate();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="6" padding-md="8">
        <Container padding="0 0 4">
          <Flex placeItems="left center">
            <Text textStyle="heading6" textStyle-md="heading4">
              {OverviewModalContent.title}
            </Text>
          </Flex>
        </Container>
        <Container>
          Please ensure the{' '}
          <Link
            href={getLinkWithAnalytics(OverviewSources.AU.latitude_low_rate_mastercard)}
            newTab
            onClick={() => trackEventSender(OverviewSources.AU.latitude_low_rate_mastercard.label)}
          >
            {getProductName()}
          </Link>{' '}
          credit card is right for you.
        </Container>
        <Container padding="6 0">
          <List>
            {OverviewModalContent.body.map((content, key) => {
              return (
                <List.Item key={`benefit-list-item-${key}`}>
                  <Text textStyle="body1" testId={`textDescription-${key}`}>
                    {content}
                  </Text>
                </List.Item>
              );
            })}
          </List>
        </Container>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};

export { LowRateFeaturesAndBenefits };
