import React from 'react';
import styled from '@emotion/styled';
import { Accordion, Text, Container, Stack, List, Link } from 'basis';
import {
  config,
  OverviewSources,
  PRODUCT_CODE_AFS,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_LOW_RATE,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
} from '_config';
import { byCard, is28D, isAU, getConditionsOfUseTerm } from 'utils';
import { EarnRateTiers } from 'components/earn-rate-tiers/EarnRateTiers';
import { getRatesAndFees } from 'featureToggles';
import { OverviewModalContentLowRate } from 'siteContent/overviewContent';

const ListWrapper = styled.div`
  li {
    &:before {
      ${props => props.dark && `background-color: ${props.theme?.colors?.grey.t75};`}
    }
  }
`;

export const BenefitInfo = () => {
  const ratesAndFees = getRatesAndFees();
  const { body } = OverviewModalContentLowRate();
  const lowRateContent = body?.reduce((acc, item) => {
    acc.push({ content: item });
    return acc;
  }, []);

  const creditCardBenefits = byCard({
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: [
      {
        title: 'Everyday credit card',
        content: 'with the ability to earn David Jones Points.',
      },
      {
        title: 'Annual fee -',
        content: `$${ratesAndFees?.annualFee}`,
      },
      {
        title: 'Interest rates -',
        content: `${ratesAndFees?.purchaseAnnualRate}% p.a. on everyday purchases and ${ratesAndFees?.cashAnnualRate}% p.a. on cash advances`,
      },
      {
        title: 'Earn David Jones Points -',
      },
      {
        title: 'Exclusive access to David Jones sales, previews,',
        content: 'complimentary express delivery, gift wrapping and more.',
      },
      {
        title: 'Discounts',
        content: 'on selected hotel bookings through Expedia, Wotif, & Booking.com',
      },
    ],
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: [
      {
        title: 'Everyday credit card',
        content: 'with the ability to earn David Jones Points.',
      },
      {
        title: 'Annual fee -',
        content: `$${ratesAndFees?.annualFee}`,
      },
      {
        title: 'Interest rates -',
        content: `${ratesAndFees?.purchaseAnnualRate}% p.a. on everyday purchases and ${ratesAndFees?.cashAnnualRate}% p.a. on cash advances`,
      },
      {
        title: 'Earn David Jones Points -',
      },
      {
        title: 'Exclusive access to David Jones sales, previews and events,',
        content: 'complimentary same day delivery, gift wrapping and alterations when shopping instore at David Jones.',
      },
      {
        title: 'Complimentary travel insurance',
      },
      {
        title: 'Discounts',
        content: 'on selected hotel bookings through Expedia, Wotif, & Booking.com',
      },
      {
        title: 'Free',
        content: 'additional cardholders',
      },
    ],
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: [
      {
        content:
          'No international transaction or currency conversion fees on purchases online or overseas. T&Cs, card fee and other charges apply.',
      },
      {
        content: `Enjoy up to $${ratesAndFees.annualReward} in Latitude Rewards each year. T&Cs and minimum monthly spend for 12 consecutive months applies.`,
      },
      {
        content:
          'Complimentary E-Commerce Protection and Purchase Protection Insurances - Cover for the damage or theft of purchases and from non-delivery and/or incomplete delivery of online purchases. T&Cs apply.',
      },
      {
        content:
          'Enjoy a range of credit back offers, discounts and deals from big brands at home and abroad, like Mastercard Priceless Specials, Booking.com, Expedia, Wotif and Hotels.com. Exclusions and T&Cs apply.',
      },
      {
        content:
          'Free Flight Delay Pass - Food, drinks and free Wi-Fi in a selection of airport lounges if your flight is delayed 2+ hours. T&Cs apply.',
      },
      {
        content:
          'Free Global Data Roaming - 3GB free data each calendar year & 15% off any extra data purchased in over 150 countries. T&Cs apply.',
      },
      {
        content:
          'Great foreign exchange rates set by Mastercard, so you get foreign currencies at the same rate we do.',
      },
      { content: 'Peace of mind with fraud protection.' },
      { content: 'Add an additional cardholder at no extra fee.' },
      {
        content: (
          <>
            Card fee of ${ratesAndFees.monthlyFee} per month (see other{' '}
            <Link href={OverviewSources.AU.latitude_28d_mastercard.url} newTab>
              Rates and Fees
            </Link>{' '}
            here).
          </>
        ),
      },
      {
        content: `Interest Rates - ${ratesAndFees.purchaseAnnualRate}% p.a interest rate on everyday purchases, ${ratesAndFees.cashAnnualRate}% p.a interest rate on cash advances.`,
      },
      {
        content: (
          <>
            Before making a decision, please read the{' '}
            <Link
              /* cspell:disable-next-line */
              href={`${OverviewSources.AU.twenty_eight_degrees_key_facts_sheet.url}?gemid2=cc-funnel%3A28degrees%3Aintro%3Alanding%3AKey%2520Facts%2520Sheet%3Awhat-to-expect&_gl=1*ul0ifv*_gcl_aw*R0NMLjE3MjAxNTY2MjMuRUFJYUlRb2JDaE1JdWR5WjNwS1Bod01WdnFWbUFoMW1IQXhTRUFBWUFTQUFFZ0lLdF9EX0J3RQ..*_gcl_dc*R0NMLjE3MjAxNTY2MjMuRUFJYUlRb2JDaE1JdWR5WjNwS1Bod01WdnFWbUFoMW1IQXhTRUFBWUFTQUFFZ0lLdF9EX0J3RQ..*_gcl_au*NTU2OTA2NzA0LjE3MTkxOTUzMjY.*_ga*MTEzOTY5NjIwOC4xNzEyMjgyNzkx*_ga_6ENXTN2K3T*MTcyNDczNzEwOC4xOTkuMS4xNzI0NzM3MzIwLjU1LjAuMA..*_ga_VZY6JWYT4Y*MTcyNDczNzEwOC4xNjQuMS4xNzI0NzM3MzIwLjU1LjAuMA..*_ga_HN02VX7PXM*MTcyNDczNzEwOC4yMDMuMS4xNzI0NzM3MzIwLjU1LjAuMA..`}
              newTab
            >
              Key Facts Sheet
            </Link>{' '}
            and{' '}
            <Link
              /* cspell:disable-next-line */
              href={`${OverviewSources.AU.twenty_eight_degrees_conditions_of_use.url}?gemid2=cc-funnel%3A28degrees%3Aintro%3Alanding%3AConditions%2520of%2520Use%3Awhat-to-expect&_gl=1*1p0gc2t*_gcl_aw*R0NMLjE3MjAxNTY2MjMuRUFJYUlRb2JDaE1JdWR5WjNwS1Bod01WdnFWbUFoMW1IQXhTRUFBWUFTQUFFZ0lLdF9EX0J3RQ..*_gcl_dc*R0NMLjE3MjAxNTY2MjMuRUFJYUlRb2JDaE1JdWR5WjNwS1Bod01WdnFWbUFoMW1IQXhTRUFBWUFTQUFFZ0lLdF9EX0J3RQ..*_gcl_au*NTU2OTA2NzA0LjE3MTkxOTUzMjY.*_ga*MTEzOTY5NjIwOC4xNzEyMjgyNzkx*_ga_6ENXTN2K3T*MTcyNDczNzEwOC4xOTkuMS4xNzI0NzM3NzAzLjYwLjAuMA..*_ga_VZY6JWYT4Y*MTcyNDczNzEwOC4xNjQuMS4xNzI0NzM3NzAzLjYwLjAuMA..*_ga_HN02VX7PXM*MTcyNDczNzEwOC4yMDMuMS4xNzI0NzM3NzAzLjYwLjAuMA..`}
              newTab
            >
              {getConditionsOfUseTerm()}
            </Link>{' '}
            to ensure you're aware of all the fees, charges and interest rates.
          </>
        ),
      },
    ],
    [PRODUCT_CODE_GO]: [
      {
        title: 'Interest free offers available from 6 to 60 months',
        content: "at thousands of Australia's leading retailers",
      },
      {
        title: 'Enjoy 6 months interest free',
        content: (
          <>
            {' '}
            on your everyday purchases of $250 or more. Select your purchases in the Latitude app within 30 days.{' '}
            <Link
              href="https://latitudefs.zendesk.com/hc/en-au/articles/4409706884113-Latitude-GO-Mastercard-s-6-months-Interest-Free?gemid2=gomaster-web%3Ahome%3Ahome%3Afind-out-more-about-interest-free-plans%3Anot-specified&_ga=2.257401929.334489883.1678660167-870083444.1658972219&tlga=870083444.1658972219&adobe_mc=MCMID%3D44700276529128657573084291899886610427%7CMCAID%3D317F733E359B3AB7-600005BEE0CBAD2A%7CMCORGID%3DB6D9B74F57B2FBE97F000101%2540AdobeOrg%7CTS%3D1678660171"
              newTab
            >
              Find more details.
            </Link>
          </>
        ),
      },
      {
        title: 'Everyday credit card',
        content: 'with a wide range of payment options that suits every budget size',
      },
      {
        title: 'Up to 55 days interest free',
        content: <>on everyday purchases&#185;</>,
      },
      {
        title: 'Interest rates -',
        content: `${ratesAndFees.purchaseAnnualRate}% p.a. interest rate on everyday purchases, ${ratesAndFees.cashAnnualRate}% p.a. on cash advances and ${ratesAndFees.expiredPromotionalPurchaseRate}% p.a on expired Interest Free Payment Plans`,
      },
      {
        title: 'Account Fee ',
        content: (
          <>
            - ${ratesAndFees.monthlyFee} monthly account service fee (see other{' '}
            <Link href="https://www.latitudefinancial.com.au/credit-cards/gomastercard/" newTab>
              Rates and Fees
            </Link>{' '}
            here)
          </>
        ),
      },
      {
        title: 'Safe and secure',
        content: '- Shop knowing that your card and account are protected by stringent security systems',
      },
      {
        title: 'Before making a decision, please read',
        content: (
          <>
            the{' '}
            <Link href="https://assets.latitudefinancial.com/legals/key-fact-sheet/gomc/kfs.html" newTab>
              Key Facts Sheet
            </Link>{' '}
            and{' '}
            <Link href="https://assets.latitudefinancial.com/legals/conditions-of-use/gomc-au/cou.pdf" newTab>
              {getConditionsOfUseTerm()}
            </Link>{' '}
            to ensure you're aware of all the fees, charges and interest rates.
          </>
        ),
      },
    ],
    [PRODUCT_CODE_AU_GEM]: [
      {
        content: (
          <>
            Enjoy 6 months interest free on all purchases $250 and over and up to 55 days interest free on everyday
            purchases under $250&#94;
          </>
        ),
      },
      {
        content: "Interest free offers available from 6 to 60 months at thousands of Australia's leading retailers",
      },
      {
        content:
          'Get exclusive deals, discounts and offers by simply using your Latitude Gem Visa at home and overseas',
      },
      {
        content: `Interest Rates - ${ratesAndFees.purchaseAnnualRate}% p.a. interest rate on everyday purchases, ${ratesAndFees.cashAnnualRate}% p.a. on cash advances and ${ratesAndFees.expiredPromotionalPurchaseRate}% p.a on expired Interest Free Payment Plans`,
      },
      {
        content: (
          <>
            Account Fee - ${ratesAndFees.monthlyFee} monthly account service fee (see other{' '}
            <Link href="https://www.latitudefinancial.com.au/credit-cards/gem-visa/" newTab>
              Rates and Fees
            </Link>{' '}
            here)
          </>
        ),
      },
      {
        content:
          'Safe and secure - Shop knowing that your card and account are protected by stringent security systems',
      },
      {
        content: (
          <>
            Before making a decision, please read the{' '}
            <Link href="https://assets.latitudefinancial.com/legals/key-fact-sheet/gemvisa-au/kfs.html" newTab>
              Key Facts Sheet
            </Link>{' '}
            and{' '}
            <Link href="https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-au/cou.pdf" newTab>
              {getConditionsOfUseTerm()}
            </Link>{' '}
            to ensure you're aware of all the fees, charges and interest rates.
          </>
        ),
      },
    ],
    [PRODUCT_CODE_AFS]: [
      {
        content: (
          <>
            Interest Free offers available at Apple. Visit{' '}
            <Link href="https://www.apple.com/au/shop/browse/financing" newTab>
              Apple
            </Link>{' '}
            for latest 0% offers & how to shop.
          </>
        ),
      },
      {
        content: `Interest Rates - ${ratesAndFees.cashAnnualRate?.toFixed(
          2,
        )}% p.a. on cash advances and ${ratesAndFees.expiredPromotionalPurchaseRate?.toFixed(
          2,
        )}% p.a on expired Interest Free Payment Plans`,
      },
      {
        content: (
          <>
            Account Fee - ${ratesAndFees.monthlyFee?.toFixed(2)} monthly account service fee (see other{' '}
            <Link
              href="https://www.latitudefinancial.com.au/credit-cards/apple-creditline/?tlga=1708333937.1711341649&adobe_mc=MCMID%3D14400213673656574580227832058633266700%7CMCORGID%3DB6D9B74F57B2FBE97F000101%2540AdobeOrg%7CTS%3D1721873334"
              newTab
            >
              Rates and Fees
            </Link>{' '}
            here)
          </>
        ),
      },
      {
        content:
          'Safe and secure - Shop knowing that your card and account are protected by stringent security systems',
      },
      {
        content: (
          <>
            Before making a decision, please read the{' '}
            <Link href="https://assets.latitudefinancial.com/legals/key-fact-sheet/creditline-afs/kfs.html" newTab>
              Key Facts Sheet
            </Link>{' '}
            and{' '}
            <Link href="https://assets.latitudefinancial.com/legals/conditions-of-use/creditline-afs/cou.pdf" newTab>
              {getConditionsOfUseTerm()}
            </Link>{' '}
            to ensure you're aware of all the fees, charges and interest rates.
          </>
        ),
      },
    ],
    [PRODUCT_CODE_LOW_RATE]: lowRateContent,
  });

  return (
    <>
      <Accordion testId="feature-benefit-message">
        <Accordion.Item>
          <Accordion.Item.Header>
            <Container padding="5 0">
              <Text textStyle="body1">
                <b>View credit card features and benefits</b> for more information on rewards, repayments, rates and
                fees
              </Text>
            </Container>
          </Accordion.Item.Header>
          <Accordion.Item.Content padding="6">
            <Stack gap="4">
              <Text textStyle="heading6">{config.productName} credit card features and benefits</Text>
              <ListWrapper dark={isAU() && !is28D()}>
                <List textStyle="body1">
                  {creditCardBenefits.map((benefit, index) => (
                    <List.Item key={`benefit-list-item-${index}`}>
                      <b>{benefit.title}</b>
                      {benefit.title ? ' ' : ''}
                      {benefit.content}
                      {index === 3 ? <EarnRateTiers productCode={config.productCode} /> : null}
                    </List.Item>
                  ))}
                </List>
              </ListWrapper>
            </Stack>
          </Accordion.Item.Content>
        </Accordion.Item>
      </Accordion>
      <Container margin="5 0 0 0">
        {byCard({
          [PRODUCT_CODE_GO]: (
            <Text>
              &#185;To take advantage of up to 55 days interest free on everyday credit card purchases, you need to pay
              the full closing balance (excluding un-expiring interest payment plans) on each statement of account by
              the applicable due date.
            </Text>
          ),
          [PRODUCT_CODE_AU_GEM]: (
            <Text>
              &#94;To take advantage of up to 55 days interest free on everyday credit card purchases, you need to pay
              the full closing balance (excluding un-expiring interest payment plans) on each statement of account by
              the applicable due date.
            </Text>
          ),
          default: <Text>*We may ask you to supply supporting information</Text>,
        })}
      </Container>
    </>
  );
};
