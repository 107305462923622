import React from 'react';
import { POITYPE_PAYSLIP_UPLOAD, POITYPE_DOC_UPLOAD } from 'store';
import { Container, Grid, Text, Stack, List, Link } from 'basis';
import { LayoutContent, LayoutOutcomePage, PhoneNumberLink, ReturnButton } from 'components';
import { PRODUCT_CODE_LOW_RATE, PRODUCT_DAVID_JONES } from '_config';
import { useDataLayerAndAnalyticsLinks } from 'hooks';

import { useTheme } from '@emotion/react';
import { byCountry, byCard, canGoBackToMerchant, getProductName } from 'utils';
import { ExternalLinkIcon } from '../../../components/icons';
import { UploadPayslips } from './UploadPayslips';
import { UploadDocuments } from './UploadDocuments';

export function OutcomeReferred({ storeState }) {
  const theme = useTheme();

  const RegionResponse = byCountry({
    AU: AuOutcomeReferred,
    NZ: NzOutcomeReferred,
  });

  return <RegionResponse storeState={storeState} theme={theme} />;
}

const AuOutcomeReferred = ({ storeState, theme }) => {
  const { applicationRef, applyAndBuy } = storeState;
  const productName = getProductName();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);

  return (
    <Container testId="AU-outcome-referred" margin="0 0 0 0" hasBreakpointWidth>
      <LayoutContent rowsGap="0">
        <Container padding="0" margin="0">
          <Grid preset="page" colsGap={6}>
            <Grid.Item colSpan="all">
              <Grid rowsGap={6}>
                <Container padding="8 0 0 0">
                  <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
                    We have received your application!
                  </Text>
                </Container>
                <Text>
                  Application reference number: <strong data-hj-suppress>{`${applicationRef}`}</strong>
                </Text>
                <Text>
                  Thank you for applying for a {productName}{' '}
                  {byCard({
                    [PRODUCT_CODE_LOW_RATE]: null,
                    default: 'credit card',
                  })}
                  .
                </Text>
                <Text>
                  Right now, your application is being reviewed by one of our team members.
                  <div>We'll get back to you as soon as possible to confirm next steps.</div>
                </Text>

                {canGoBackToMerchant(storeState) && (
                  <>
                    <Text>To continue shopping online at {applyAndBuy?.merchantGroupName}, you can:</Text>
                    <List type="ordered">
                      <List.Item>Shop using another form of payment; or</List.Item>
                      <List.Item>
                        Save your items to your wish list. Once you’re approved, you can complete your purchase.
                      </List.Item>
                    </List>
                    <Container>
                      <Link
                        href={applyAndBuy.upstreamUrl}
                        appearance="primary-button"
                        testId="return-to-merchant-btn"
                        margin="6 0"
                        onClick={() => {
                          trackEventSender({
                            location: 'outcome',
                            label: `Return to ${applyAndBuy?.merchantGroupName}`,
                            action: 'external-link',
                          });
                        }}
                        newTab
                      >
                        Return to {applyAndBuy?.merchantGroupName} <ExternalLinkIcon style={{ marginLeft: '8px' }} />
                      </Link>
                    </Container>
                  </>
                )}

                <Text>Thank you for your patience.</Text>
                <Text>
                  The {productName}{' '}
                  {byCard({
                    [PRODUCT_DAVID_JONES]: 'credit card team',
                    default: 'Team',
                  })}
                </Text>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </LayoutContent>
    </Container>
  );
};

const NzOutcomeReferred = ({ storeState, theme }) => {
  const { incomeVerificationType, applicationRef } = storeState;

  // NZ allows customer to upload payslip documents or other documents in Referred status.
  if (incomeVerificationType === POITYPE_PAYSLIP_UPLOAD) {
    return <UploadPayslips storeState={storeState} />;
  }
  if (incomeVerificationType === POITYPE_DOC_UPLOAD) {
    return <UploadDocuments storeState={storeState} />;
  }

  return (
    <LayoutOutcomePage>
      <Stack testId="NZ-outcome-referred" gap="8">
        <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
          We’ve successfully received your application
        </Text>
        <Text>
          Application number: <strong data-hj-suppress>{`${applicationRef}`}</strong>
        </Text>
        <Text>Thanks for applying.</Text>
        <Text>
          We've received your application and it has been referred for further review. We'll be sending an email to you
          shortly so keep an eye out for that email which contains more details about your application.
        </Text>
        <Text>
          If you have any questions in the meantime, please call us on <PhoneNumberLink id="originationsTeam" />.
        </Text>
        <ReturnButton />
      </Stack>
    </LayoutOutcomePage>
  );
};
