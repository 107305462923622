import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useOktaClient } from 'hooks';
import { BasisOneTimeCode } from './BasisOneTimeCode';

export function OneTimeCode({ resendMsecs, onResendCodeClick, onResendCodeError, resend, formData, ...rest }) {
  const [state, setState] = useState('locked');
  const requestAfterLock = useRef(false);
  const timeout = useRef(null);
  const { factor } = rest;

  const handleResendCodeSuccess = () => {
    setState('success');
  };
  const handleResendCodeError = error => {
    onResendCodeError && onResendCodeError(error);
    setState('error');
  };
  const { loading, resendOTP: resendOTPViaOkta } = useOktaClient({
    onSuccess: handleResendCodeSuccess,
    onError: handleResendCodeError,
  });

  const resendOTP = useCallback(async () => {
    if (!resend) {
      return resendOTPViaOkta(factor);
    }

    const result = await resend();
    if (result.success) {
      setState('success');
    } else {
      onResendCodeError && onResendCodeError(result.error);
      setState('error');
    }

    return result;
  }, [factor, onResendCodeError, resend, resendOTPViaOkta]);

  const handleResendCodeClick = evt => {
    evt.preventDefault();
    onResendCodeClick && onResendCodeClick();
    formData.resetForm({
      values: {
        verificationCode: '',
      },
      errors: {},
    });
    if (state === 'locked') {
      requestAfterLock.current = true;
      setState('pending');
    } else {
      resendOTP();
    }
  };

  const clearTimer = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = null;
  }, [timeout]);

  useEffect(() => {
    if (!timeout.current && (state === 'locked' || state === 'success' || state === 'error')) {
      timeout.current = setTimeout(() => {
        setState('ready');
        clearTimer();
        if (requestAfterLock.current) {
          requestAfterLock.current = false;
          resendOTP();
        }
      }, resendMsecs);
    }
  }, [state, resendMsecs, requestAfterLock, resendOTP, clearTimer, factor]);

  useEffect(() => {
    return () => clearTimer();
  }, [clearTimer]);

  return <BasisOneTimeCode state={state} loading={loading} onResendCodeClick={handleResendCodeClick} {...rest} />;
}
