import React from 'react';
import { Text, Stack, Container, Grid, Link } from 'basis';
import { LayoutOutcomePage, LayoutContent, ReturnButton } from 'components';
import { APPLE_MERCHANT } from '_config/_constants';
import { useTheme } from '@emotion/react';
import { isAU, byCard, getProductName } from 'utils/byConfig';
import { canGoBackToMerchant } from 'utils';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { PRODUCT_CODE_LOW_RATE } from '_config';
import { ExternalLinkIcon } from '../../../components/icons';
import { AppleApplicantsBox } from '../components/AppleApplicantsBox';

export const OutcomeDeclined = ({ storeState }) => {
  const {
    applyAndBuy: { merchantName },
  } = storeState;
  const theme = useTheme();
  const OutcomeDeclinedNZ = () => {
    return (
      <LayoutOutcomePage>
        <Stack gap="8">
          <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
            We were unable to approve your application
          </Text>
          <Text>
            Unfortunately, at this moment in time, we're unable to approve your application because it didn't meet our
            lending criteria.
          </Text>
          <Text>
            Usually, there's no single factor that causes an application to be declined. Often, it's a combination of
            factors relating to your personal and financial circumstances.
          </Text>
          <Text>
            We do, however, recognise that these factors might change in the future and we'd happily welcome another
            application if they do change.
          </Text>
          <ReturnButton />
        </Stack>

        {merchantName === APPLE_MERCHANT && <AppleApplicantsBox storeState={storeState} />}
      </LayoutOutcomePage>
    );
  };

  const OutcomeDeclinedAU = () => {
    const { applicationRef, applyAndBuy } = storeState;
    const productName = getProductName();
    const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);

    return (
      <Container margin="0 0 0 0" hasBreakpointWidth>
        <LayoutContent rowsGap="0">
          <Container padding="0" margin="0">
            <Grid preset="page" colsGap={11}>
              <Grid.Item colSpan="all">
                <Grid rowsGap={6}>
                  <Container padding="8 0 0 0">
                    <Stack gap="8">
                      <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
                        Thank you for your recent application.
                      </Text>
                      <Text>
                        Application reference number: <strong data-hj-suppress>{`${applicationRef}`}</strong>
                      </Text>
                      <Text>
                        Thank you for applying for a {productName}{' '}
                        {byCard({
                          [PRODUCT_CODE_LOW_RATE]: null,
                          default: 'credit card',
                        })}
                        . We appreciate your interest in applying with us. However, on this occasion, we cannot approve
                        your application.
                      </Text>
                      <Text>
                        More details will be sent to your email address and if your circumstances change, you are
                        welcome to reapply in the future.
                      </Text>
                      {canGoBackToMerchant(storeState) && (
                        <>
                          <Text>
                            To continue shopping online at {applyAndBuy?.merchantGroupName} using another form of
                            payment, click the button below.
                          </Text>
                          <Container>
                            <Link
                              href={applyAndBuy.upstreamUrl}
                              appearance="primary-button"
                              testId="return-to-merchant-btn"
                              margin="6 0"
                              onClick={() => {
                                trackEventSender({
                                  location: 'outcome',
                                  label: `Return to ${applyAndBuy?.merchantGroupName}`,
                                  action: 'external-link',
                                });
                              }}
                              newTab
                            >
                              Return to {applyAndBuy?.merchantGroupName}{' '}
                              <ExternalLinkIcon style={{ marginLeft: '8px' }} />
                            </Link>
                          </Container>
                        </>
                      )}
                    </Stack>
                  </Container>
                </Grid>
              </Grid.Item>
            </Grid>
          </Container>
        </LayoutContent>
      </Container>
    );
  };

  return isAU() ? <OutcomeDeclinedAU storeState={storeState} /> : <OutcomeDeclinedNZ storeState={storeState} />;
};
