import React, { useRef, useState } from 'react';
import { Text, useBreakpoint, Container, Stack } from 'basis';
import { byCard } from 'utils';
import { PRODUCT_CODE_TWENTY_EIGHT_DEGREES } from '_config';
import styled from '@emotion/styled';
import { Icon } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { LandingContentItem, OverviewBox } from './LandingPageAu.styles';

import ProfileIcon from '../../images/icons/profile-single.svg';
import HomeIcon from '../../images/icons/home.svg';
import WalletIcon from '../../images/icons/finance-wallet.svg';
import MoneyIcon from '../../images/icons/finance-pay-manual.svg';
import IdentityIcon from '../../images/icons/identity.svg';
import StopwatchIcon from '../../images/icons/stopwatch.svg';

export const ApplicationOverview = ({ formData }) => {
  const items = [
    {
      title: 'Who can apply?',
      body: (
        <>
          <LandingContentItem>
            <img src={ProfileIcon} alt="profile-icon" />
            <Text as="p">You must be aged 18 or older</Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={HomeIcon} alt="house-icon" />
            <Text>
              You must be a resident of Australia or a temporary resident who legally has the right to work in Australia
              (and intends to stay) for at least the next 12 months
            </Text>
          </LandingContentItem>
        </>
      ),
    },
    {
      title: byCard({
        [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: 'Details to have handy',
        default: "What you'll need",
      }),
      body: (
        <>
          <LandingContentItem>
            <img src={MoneyIcon} alt="money-icon" />
            <Text>Income, debts and expenses information</Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={IdentityIcon} alt="id-icon" />
            <Text as="p">
              Proof of ID (Australian Driver Licence, Medicare or passport) if you consent to using your ID documents to
              verify your identity
            </Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={WalletIcon} alt="wallet-icon" />
            <Text as="p">Proof of income (such as payslips, bank statements, tax statements)</Text>
          </LandingContentItem>
        </>
      ),
    },
    {
      title: 'How long will it take?',
      body: (
        <>
          <LandingContentItem>
            <img src={StopwatchIcon} alt="money-icon" />
            <Text>
              You will need 10-15 minutes to complete the application
              <br />
              (If we need additional information from you, we will contact you within 2 business days.)
            </Text>
          </LandingContentItem>
        </>
      ),
    },
  ];

  return (
    <AccordionWhenXS>
      <Stack gap="2">
        <OverviewBox>
          {items.map((item, index) => {
            return (
              <Container margin="0 0 6 0" key={`overview-item-${index}`}>
                <Container margin="0 0 4 0">
                  <Text textStyle="heading5" as="h4">
                    {item.title}
                  </Text>
                </Container>
                {item.body}
              </Container>
            );
          })}
        </OverviewBox>
      </Stack>
    </AccordionWhenXS>
  );
};

const AccordionWrapper = styled.div`
  margin-top: -32px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  transition: height 0.3s ease;
  height: ${props => (props.isOpen ? 'auto' : '60px')}; // Adjust 48px to match your header height
  overflow: hidden;
`;

const AccordionHeader = styled.div`
  background-color: #f5f5f5;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #414141;
`;

const AccordionContent = styled.div`
  padding: 18px;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
`;

const AccordionWhenXS = ({ children }) => {
  const breakpoint = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // default: 0-374px, xs: 375px-575px,
  if (breakpoint !== 'default' && breakpoint !== 'xs') {
    return children;
  }

  return (
    <AccordionWrapper isOpen={isOpen}>
      <AccordionHeader onClick={toggleAccordion}>
        <Text>
          <strong>Things to know before you start</strong>
        </Text>
        <Icon
          as={ChevronDownIcon}
          transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
          transition="transform 0.3s ease"
          boxSize="24px"
        />
      </AccordionHeader>
      <AccordionContent ref={contentRef}>{children}</AccordionContent>
    </AccordionWrapper>
  );
};
