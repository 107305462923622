import { useState } from 'react';
import axios from 'axios';
import {
  useStoreValue,
  OKTA_EMAIL_FACTOR,
  OKTA_SMS_FACTOR,
  ERROR_KIND_SYSTEM_ERROR,
  OKTA_EMPTY_MOBILE_NUMBER_ERROR,
} from 'store';
import { DEV_FEATURES } from '__dev/devFeatures';
import { byCountry } from 'utils/byConfig';
import { datadogRum } from '@datadog/browser-rum';
import { useOktaClient } from './useOktaClient';
import { useHandleApiErrors } from './useHandleApiErrors';

const registerURL = process.env.REACT_APP_USER_REGISTER_URL;

/**
 * Register a user and login with Okta
 * @param {Object} params
 * @param {({factor: "email" | "sms"}) => void} params.onSuccess - Callback function for successful registration
 * @param {Function} params.onError - Callback function for error handling
 * @returns {Object} - Object containing loading state and submit function
 */
const useRegisterAndLoginUser = ({ onSuccess, onError }) => {
  const [{ applicationId }, updateStore] = useStoreValue();
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors('axios', onError);
  const { loginAndSendOTP } = useOktaClient({ onError });

  const submit = async ({ email, mobile }) => {
    const mobilePhone =
      DEV_FEATURES.OTP_MOBILE_NUMBER ||
      byCountry({
        AU: `+61${mobile.slice(1)}`,
        NZ: `+64${mobile.slice(1)}`,
      });

    const payload = {
      profile: {
        email,
        mobile_phone: mobilePhone,
      },
    };

    const options = {
      headers: {
        'x-correlation-id': applicationId,
        'lfs-request-channel': byCountry({
          NZ: process.env.REACT_APP_OKTA_HEADER,
          AU: process.env.REACT_APP_OKTA_HEADER_AU,
        }),
      },
    };

    try {
      setLoading(true);

      const registerData = await axios.post(registerURL, payload, options);

      if (registerData.data.profile.masked_mobile_phone) {
        updateStore({
          applicationErrors: null,
          oktaProfileHealthy: true,
          maskedMobileNumber: registerData.data.profile.masked_mobile_phone
            ?.replace('+61', '0')
            ?.replace('+64', '0')
            ?.replace('****', ' **** '),
        });
        const factor = OKTA_SMS_FACTOR;
        const success = await loginAndSendOTP(email, factor);
        success && onSuccess && onSuccess({ factor });
      } else {
        // Okta profile `registerData.data.profile` is an empty object `{}`, so fallback to login via email
        const factor = OKTA_EMAIL_FACTOR;

        const error = new Error(`Okta user profile is empty {}. Email: ${email}. Application ID: ${applicationId}.`);
        error.kind = ERROR_KIND_SYSTEM_ERROR;
        error.fingerprint = `OKTA_EMPTY_MOBILE_NUMBER::email::${email}`;
        error.type = OKTA_EMPTY_MOBILE_NUMBER_ERROR;
        datadogRum.addError(error);

        updateStore({
          applicationErrors: null,
          oktaProfileHealthy: false,
        });
        const success = await loginAndSendOTP(email, factor);
        success && onSuccess && onSuccess({ factor });
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    submit,
  };
};

export { useRegisterAndLoginUser };
