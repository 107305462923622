/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { Button, Grid } from 'basis';
import { useHistory } from 'react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import { useStoreValue, OKTA_VERIFICATION_ERROR, OKTA_SMS_FACTOR } from 'store';
import { LayoutPage, LayoutContent, Form, Errors, SecureIcon, OneTimeCode } from 'components';
import { byCountry, getDataLayerElements, submitHandler, isAU } from 'utils';
import { useFormInitialValues, useSteps, useApplicationMobileVerify } from 'hooks';
import { RESEND_MIN } from '_config/_constants/verificationPage';
import { STEP_PROCESSING } from '_config';
import styled from '@emotion/styled';
import { useFocusOnFirstFormElement } from '../../hooks/useFocusOnFirstFormElement';

const FORM_ID = 'verification';
const DEFAULT_ERROR_MESSAGE = 'The verification code you entered is incorrect. Please try again.';
const ERROR_MESSAGES = {
  UNPROCESSABLE_ENTITY: DEFAULT_ERROR_MESSAGE,
  BAD_REQUEST: DEFAULT_ERROR_MESSAGE,
  UNAUTHORIZED: 'System error. Please try again later.',
  NOT_FOUND: 'System error. Please try again later.',
  // Note, verifyOtpSMS endpoint returns generic internal server error when user enters wrong OTP...
  INTERNAL_SERVER_ERROR: 'Verification failed. Please try again.',
};

/**
 * Handles mobile number verification for users with unverified Okta profiles.
 *
 * Since Okta client's `/identity/verify` login endpoint cannot process OTP verification for unverified numbers,
 * this component uses an alternative endpoint to verify mobile numbers stored in
 * the application rather than in the Okta profile.
 */
export function VerifyApplicationMobile() {
  const [storeState, updateStore] = useStoreValue();
  const factor = OKTA_SMS_FACTOR;

  const { pathname } = useSteps();
  const history = useHistory();
  const { trackEvent } = useEventTracking();
  const initialValues = useFormInitialValues(FORM_ID);
  const { sending, resendOtpSMS, verifyOtpSMS } = useApplicationMobileVerify({
    applicationId: storeState.applicationId,
  });

  useFocusOnFirstFormElement();

  const noProgressStepper = isAU();

  const onResendCodeClick = () => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: pathname.slice(1),
        label: 'Resend code',
      },
    });
  };

  const doSubmit = useCallback(
    async (verification, setErrors) => {
      const newAcquisition = {
        ...storeState.acquisition,
        verification: { ...verification },
      };
      updateStore({ acquisition: newAcquisition });

      trackEvent({
        event: {
          category: 'application',
          action: 'application-navigation',
          location: pathname.slice(1),
          label: 'Verify',
        },
        ...getDataLayerElements(storeState),
      });

      const result = await verifyOtpSMS(verification.verificationCode);
      const { success, error: otpResponseStatus } = result;
      if (success) {
        const nextStep = STEP_PROCESSING;
        updateStore({
          activeStep: nextStep,
          applicationErrors: null,
          didApplicationMobileVerification: true,
        });
        history.push(nextStep);
      } else {
        setErrors({ verificationCode: ERROR_MESSAGES[otpResponseStatus] || DEFAULT_ERROR_MESSAGE });
      }
    },
    [history, pathname, storeState, trackEvent, updateStore, verifyOtpSMS],
  );

  const onSubmit = ({ values, errors, setErrors }) => {
    async function verifyOTPAndRedirect() {
      doSubmit(values, setErrors);
    }

    submitHandler({ submit: verifyOTPAndRedirect, errors });
  };

  if (isAU() && storeState.applicationErrors?.type === OKTA_VERIFICATION_ERROR) {
    // i.e. AU customer lost network
    return (
      <LayoutPage noProgressStepper={noProgressStepper} hideBackButton>
        <Errors applicationRef={storeState.applicationRef} retry={resendOtpSMS} retrying={sending} isPreSubmission />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage noProgressStepper={noProgressStepper} hideTitleIfErrors={false} hideBackButton>
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onSubmit}
        loading={sending}
        submitButtonLabel="Verify"
        disableFormCache
        hideFormButtons={byCountry({
          AU: true,
          NZ: false,
        })}
      >
        {formData => {
          return (
            <LayoutContent>
              <OneTimeCode
                name="verificationCode"
                label="Verification code"
                testId="verification-code"
                codeLength="4"
                factorTarget={storeState.maskedMobileNumber || storeState.acquisition.contactDetails.mobileNumber}
                resendMsecs={RESEND_MIN * 60000}
                resend={resendOtpSMS}
                onResendCodeClick={onResendCodeClick}
                onResendCodeError={onResendCodeClick}
                factor={factor}
                formData={formData}
              />
              {isAU() ? <VerifyButton loading={sending} /> : null}
            </LayoutContent>
          );
        }}
      </Form>
    </LayoutPage>
  );
}

const VerifyButton = ({ loading }) => (
  <Grid colsGap="10" cols="2">
    <Grid.Item colSpan="all" colSpan-sm="0" alignItems="center">
      <Grid>
        <Button testId="verify-btn" type="submit" loading={loading}>
          <IconAndText>
            <SecureIcon color="white" />
            Verify
          </IconAndText>
        </Button>
      </Grid>
    </Grid.Item>
  </Grid>
);

const IconAndText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: 8px;
    margin-top: -2px;
  }
`;
