import { gql, useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { ERROR_KIND_SYSTEM_ERROR, ERROR_KIND_USER_ERROR, useStoreValue } from 'store';

import { useErrorTracking } from './useErrorTrackingAndUpdateStore';

export const SEND_OTP_SMS_GQL = gql`
  mutation sendOtpSMS($input: SendOtpSMSInput!) {
    sendOtpSMS(input: $input) {
      receiptId
      masked_mobile
    }
  }
`;

export const RESEND_OTP_SMS_GQL = gql`
  mutation resendOtpSMS($input: ResendOtpSMSInput!) {
    resendOtpSMS(input: $input) {
      status
    }
  }
`;

export const VERIFY_OTP_SMS_GQL = gql`
  mutation verifyOtpSMS($input: VerifyOtpSMSInput!) {
    verifyOtpSMS(input: $input) {
      status
    }
  }
`;

/**
 * Handles mobile number verification for users with unverified Okta profiles.
 *
 * Since Okta client's `/identity/verify` login endpoint cannot process OTP verification for unverified numbers,
 * this hook uses an alternative endpoint to verify mobile numbers stored in
 * the application rather than in the Okta profile.
 *
 * @param {string} applicationId
 * @returns {{ sending: boolean, errorStatus: string | null, resendOtpSMS: function, verifyOtpSMS: function }}
 * @property {string | null} errorStatus - OK | BAD_REQUEST | UNAUTHORIZED | NOT_FOUND
 * @property {boolean} sending - Whether the OTP is being sent or verified.
 * @property {function} resendOtpSMS - Resends the OTP.
 * @property {function} verifyOtpSMS - Verifies the OTP.
 */
export function useApplicationMobileVerify() {
  const [storeState, updateStore] = useStoreValue();
  const [receiptId, setReceiptId] = useState(null);
  const [sending, setSending] = useState(false);
  const [errorStatus, setError] = useState(null);

  const [sendOtpSMSRequest] = useMutation(SEND_OTP_SMS_GQL);
  const [resendOtpSMSRequest] = useMutation(RESEND_OTP_SMS_GQL);
  const [verifyOtpSMSRequest] = useMutation(VERIFY_OTP_SMS_GQL);

  const { handleErrorTracking } = useErrorTracking('hook:useApplicationMobileVerify');

  const sendOtpSMS = useCallback(async () => {
    try {
      setError(null);
      setSending(true);

      const result = await sendOtpSMSRequest({ variables: { input: { applicationId: storeState.applicationId } } });

      if (!result.data || !result.data?.sendOtpSMS?.receiptId) {
        const status = 'INTERNAL_SERVER_ERROR';
        handleError(status, setError, handleErrorTracking);
        return { success: false, error: status };
      }

      setReceiptId(result.data.sendOtpSMS.receiptId);

      if (result.data.sendOtpSMS.masked_mobile) {
        updateStore({ maskedMobileNumber: result.data.sendOtpSMS.masked_mobile });
      }

      return { success: true };
    } catch (error) {
      handleError(error, setError, handleErrorTracking);
      const status = 'INTERNAL_SERVER_ERROR';
      return { success: false, error: status };
    } finally {
      setSending(false);
    }
  }, [handleErrorTracking, sendOtpSMSRequest, storeState.applicationId, updateStore]);

  useEffect(() => {
    if (!receiptId) {
      sendOtpSMS();
    }
  }, [receiptId, sendOtpSMS, sendOtpSMSRequest, storeState.applicationId, updateStore]);

  /**
   * @deprecated do not use this endpoint as it has expiration limit and its response does not contain expiration information.
   */
  // eslint-disable-next-line no-unused-vars
  const resendOtpSMSViaResendEndpoint = useCallback(async () => {
    setError(null);
    setSending(true);
    const result = await resendOtpSMSRequest({
      variables: { input: { applicationId: storeState.applicationId, receiptId } },
    });
    const { status } = result.data.resendOtpSMS;

    if (status === 'OK') {
      return { success: true };
    }
    handleError(status, setError, handleErrorTracking);
    return { success: false, error: status };
  }, [handleErrorTracking, receiptId, resendOtpSMSRequest, storeState.applicationId]);

  const verifyOtpSMS = useCallback(
    async otp => {
      try {
        setError(null);
        setSending(true);
        const result = await verifyOtpSMSRequest({
          variables: { input: { applicationId: storeState.applicationId, receiptId, otp } },
        });
        setSending(false);

        if (!result.data || !result.data?.verifyOtpSMS?.status || result.data.verifyOtpSMS.status !== 'OK') {
          const status = result?.data?.verifyOtpSMS?.status || 'INTERNAL_SERVER_ERROR';
          handleError(status, setError, handleErrorTracking);
          return { success: false, error: status };
        }

        return { success: true };
      } catch (error) {
        const status = 'INTERNAL_SERVER_ERROR';
        // At the moment, verifyOtpSMS endpoint returns generic internal server error when user enters wrong OTP...
        // so that we cannot log the error or raise alarm on it.
        // handleError(status, setError, handleErrorTracking);

        setError(status);
        return { success: false, error: status };
      } finally {
        setSending(false);
      }
    },
    [handleErrorTracking, receiptId, storeState.applicationId, verifyOtpSMSRequest],
  );

  return {
    sendOtpSMS,
    resendOtpSMS: sendOtpSMS,
    verifyOtpSMS,
    sending,
    errorStatus,
  };
}

function handleError(errorStatus, setError, handleErrorTracking) {
  setError(errorStatus);

  switch (errorStatus) {
    case 'UNPROCESSABLE_ENTITY': {
      const err = new Error(`Invalid OTP. OtpResponseStatus: ${errorStatus}`);
      err.kind = ERROR_KIND_USER_ERROR;
      handleErrorTracking(err);
      break;
    }
    case 'UNAUTHORIZED': {
      const err = new Error(`Unauthorized. OtpResponseStatus: ${errorStatus}`);
      err.kind = ERROR_KIND_USER_ERROR;
      handleErrorTracking(err);
      break;
    }
    default: {
      const err = new Error(`OtpResponseStatus: ${errorStatus}`);
      err.kind = ERROR_KIND_SYSTEM_ERROR;
      handleErrorTracking(err);
      break;
    }
  }
}
